<template>
  <div>
    <a-modal v-model="modalVisible" title="版本升级" :width="560" :centered="true">
      <template slot="footer">
        <a-button @click="modalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :label-col="{span:7}" :wrapper-col="{span:12}" :model="formRules" :rules="formRules">
        <a-form-model-item label="设备类型">
          <a-select v-model="devicemoduletype">
            <a-select-option v-for="(item, index) in devicemoduletypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="版本">
          <a-input :value="versionObj?versionObj.version:''" style="width: 65%;" disabled />
          <a-button type="primary" style="width: 30%; margin-left: 5%" @click="selectVersionModalVisible=true">选择</a-button>
        </a-form-model-item>
        <a-form-model-item label="预计升级完成时间" prop="precompletetime">
          <a-select v-model="precompletetime" style="width: 65%">
            <a-select-option value="">请选择</a-select-option>
            <a-select-option value="3600000">1小时</a-select-option>
            <a-select-option value="21600000">6小时</a-select-option>
            <a-select-option value="43200000">12小时</a-select-option>
            <a-select-option value="86400000">1天</a-select-option>
            <a-select-option value="172800000">2天</a-select-option>
            <a-select-option value="259200000">3天</a-select-option>
            <a-select-option value="432000000">5天</a-select-option>
            <a-select-option value="864000000">10天</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <select-version-modal select-type="radio" :visible.sync="selectVersionModalVisible" :default-selected="versionObj?[versionObj]:[]" @get-selected-rows="getDevicemodelSelectRows" :devicemoduletype="devicemoduletype" @getMsg="getMsg(arguments)"></select-version-modal>
    <a-modal v-model="messVisible" title="提示信息" :width="760" :centered="true">
      <div ref="modalContainer" class="modal-container">
        <div ref="successmess" v-if="successmess">
          <div style="text-align: center">
            <i class="el-icon-circle-check"  style="font-size: 150px"></i>
          </div>
          <div style="font-size: 40px;text-align: center">升级参数发送成功！</div>
        </div>
        <div ref="defaultmess" v-if="defaultmess">
          <div style="text-align: center">
            <i class="el-icon-circle-close" style="font-size: 150px;"></i>
          </div>
          <div style="font-size: 40px;text-align: center">升级参数发送失败，请重试！</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import SelectVersionModal from 'C/modals/SelectVersionModal.vue'
import {
  addUpgradeLog, calculateVersion,
  getDeviceinfoListByCondition,
  getDevicemoduleinfoListByCondition,
  updateVersion,
  upgradeVersion
} from 'A/ai.js'
import devicemoduletypes from '@/json/devicemoduletypes'
import {getDevicegroupdevListByCondition, getDevicegroupListByCondition} from "A/ai";
import {getCache} from "U/index";
import moment from "moment";
export default {
  components: {
    SelectVersionModal,
  },
  props: {
    visible: {
      default: false
    },
    devicegroupid: {
      default: ''
    },
  },
  data() {
    return {
      devicemoduletypes,
      modalVisible: false,
      devicemoduletype: '0',
      modalnum:'',
      selectVersionModalVisible: false,
      versionObj: null,
      devicenums :[],
      messVisible:false,
      successmess:'',
      defaultmess:'',
      devicegroupname:'',
      currentDate:'',
      frontversion:'',
      devicename:'',
      msg:'',
      msg1:'',
      data:[],
      updata:[],
      precompletetime:'',
      formRules:{
        precompletetime:[{required: true,message:"请输入预计升级完成时间"}]
      },
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
    devicemoduletype(val) {
      this.versionObj = null;
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getMsg:function (msg){
      this.msg=msg[0].toString()
      this.msg1=msg[1].toString()
      this.frontversion=this.msg1
    },
    initDetail() {
      this.resetDatas();
      this.getDevicegroupname();
      this.getDevicemoduleVersion();
    },
    resetDatas() {
      this.versionObj = null;
      this.devicemoduletype = '0';
    },
    getDevicemodelSelectRows(rows) {
      this.versionObj = rows[0]
    },
    confirm() {
      if(this.precompletetime!=''&&this.precompletetime!=null){
        this.modalVisible = false;
        this.getDevicenum()
      }else{
        this.$message.info("请选择预计升级完成时间！")
      }
    },
    getDevicegroupname(){
      let params ={
        devicegroupid: this.devicegroupid,
      }
      getDevicegroupListByCondition(params).then(res=> {
        if (res && res.returncode == '0') {
          this.devicegroupname = res.item[0].devicegroupname
        }
      })
    },
    getDevicemoduleVersion(){
      let params ={
        devicenum:this.devicenum,
        devicemoduletype: this.msg,
      }
      getDeviceinfoListByCondition(params).then(res=> {
        if (res && res.returncode == '0') {
          this.devicename = res.item[0].devicename
        }
      })
    },
    insertUpgradeLog(){
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      let param={
        devicenum:'',
        modeltype: this.msg,
        frontversion:this.frontversion,
        username:this.username,
        version:this.versionObj.version,
        file:this.versionObj.filename,
        upgradedate:this.currentDate,
        upgradeobj:this.devicegroupname,
        result:'',
        md5:this.versionObj.md5,
        devicegroupid:this.devicegroupid,
        upgradetype:'0',
      }
      let params = {
        item:param
      }
      addUpgradeLog(params)
    },

    getDevicenum(){
      if(this.versionObj) {
        this.currentDate=moment(new Date).format("YYYYMMDDHHmmss");
        this.insertUpgradeLog();
        let params = {
          devicegroupid: this.devicegroupid,
        }
        let versionList=this.versionObj
        getDevicegroupdevListByCondition(params).then(res => {
          if (res && res.returncode == '0') {
            this.data=[]
            this.updata=[]
            for (let i = 0; i < res.count; i++) {
              var that = this;
              let logininfo = getCache('logininfo', true);
              if (logininfo) {
                this.username = logininfo.usernames;
              }
              let param1 = {
                devicenum: res.item[i].devicenum,
                modeltype: that.msg,
                frontversion: that.frontversion,
                username: this.username,
                version: versionList.version,
                file: versionList.filename,
                upgradedate: that.currentDate,
                upgradeobj: res.item[i].devicename,
                result: '',
                md5: versionList.md5,
                devicegroupid: that.devicegroupid,
                upgradetype: '1',
              }
              that.data[i] = param1
              let param ={
                devicenum:res.item[i].devicenum,
                data: [{
                  modeltype: this.devicemoduletype,
                  modelnum: this.modelnum,
                  version: versionList.version,
                  file: versionList.filename,
                  md5: versionList.md5,
                }],
                updatedate:this.currentDate,
              }
              this.updata[i]=param
            }
            let upparam={
              item:this.updata
            }
            updateVersion(upparam)
            this.$message.success('操作成功');
            this.$emit('get-operation-result', 'success', '操作成功');
            this.sertSuccess();
            let params = {
              item: that.data
            }
            addUpgradeLog(params)
            let data = {
              upgradedate: that.currentDate,
              modeltype: that.msg,
              upgradetype: '1',
              precompletetime: this.precompletetime,
            }
            calculateVersion(data)
          }
        })
      }else {
        this.$message.error('请选择版本');
      }
    },
    sertSuccess(){
      this.messVisible=true;
      this.successmess=true;
      setTimeout(() => {
        this.messVisible=false;
      }, 1500);
    },
    sertdefault() {
      this.messVisible=true;
      this.defaultmess=true;
      setTimeout(() => {
        this.messVisible=false;
      }, 1500);
    },
  }
}
</script>