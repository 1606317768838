<template>
  <page-container title="设备分组" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div v-if="!liftpropertytype" class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备组名称" prop="devicegroupname">
              <a-input v-model.trim="queryParams.devicegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <!-- <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button> -->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">设备组列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="devicegroupid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="deviceinfoList" slot-scope="value">
            {{value&&value.length?JSON.stringify(value):''}}
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.devicegroupid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.devicegroupid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.devicegroupid">删除</a-menu-item>
                <a-menu-item :key="'recover-'+record.devicegroupid">重启</a-menu-item>
                <a-menu-item :key="'manager-'+record.devicegroupid">关联设备</a-menu-item>
                <a-menu-item :key="'update-'+record.devicegroupid">版本升级</a-menu-item>
                <a-menu-item :key="'params-'+record.devicegroupid">设备组参数配置</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData" :liftpropertytype="liftpropertytype"></add-or-edit-modal>
    <device-manager :visible.sync="groupManagerVisible" :params="{devicegroupid:devicegroupid}"></device-manager>
    <update-version :visible.sync="updateVersionVisible" @get-operation-result="getTableData" :devicegroupid="devicegroupid"></update-version>
  </page-container>
</template>
<script>
import {getCache, getItemFromArrayByKey} from 'U'
import store from '@/store'
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {
  getDevicegroupListByCondition,
  deleteDevicegroup,
  setDeviceparamValueList,
} from 'A/ai.js'
import addOrEditModal from './addOrEditModal'
import DeviceManager from './DeviceManager'
import paramsConfig from "V/monitorDevice/monitoringEquipment/equipmentGroup/paramsConfig";
import UpdateVersion from './UpdateVersion'
import {addRecoverLog, getDevicegroupdevListByCondition, modifyRecoverLog} from "A/ai";
export default {
  name: 'equipmentGroup',
  mixins: [pagination],
  components: {
    addOrEditModal,
    DeviceManager,
    paramsConfig,
    UpdateVersion,
  },
  props: ['liftpropertytype'],
  data() {
    return {
      moment,
      breadcrumb: [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: '监控硬终端',
          path: ''
        },
        {
          name: '设备分组',
          path: ''
        },
      ],
      queryParams: {
        recoverid:'',
        devicegroupname: '',
      },
      tableColumns: [
        {
          title: '设备组名称',
          dataIndex: 'devicegroupname',
          key: 'devicegroupname',
          ellipsis: true,
        },
        // {
        //   title: '设备列表',
        //   dataIndex: 'deviceinfoList',
        //   key: 'deviceinfoList',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'deviceinfoList' }
        // },
        {
          title: '创建者',
          dataIndex: 'creator',
          key: 'creator',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'devicegroupdesc',
          key: 'devicegroupdesc',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      groupManagerVisible: false,
      devicegroupid: '',
      devicenum:'',
      paramsModalVisible:false,
      updateVersionVisible: false,
      username:'',
      devicenums:[],
      devicenames:[],
      sum:0,
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
        this.usertype = logininfo.usertype;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        creator: this.usertype==0?"":this.username,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getDevicegroupListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let devicegroupid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'devicegroupid', devicegroupid);
      if (type == 'detail') {
        this.showModal('detail', '', record)
      } else if (type == 'edit') {
        this.showModal('edit', '', record)
      } else if (type == 'delete') {
        this.deleteConfirm('', record);
      } else if (type == 'manager') {
        this.managerDevice('', record);
      } else if (type == 'params') {
        // this.paramsConfig('', record);
        this.$router.push({
          name: 'paramsConfigs',
          params: {
            prePages: [
              {
                name: '网络管理',
                path: ''
              },
              {
                name: '监控硬终端',
                path: ''
              },
              {
                name: '设备分组',
                path: 'enquipmentGroup'
              },
            ],
            devicenum: record.devicenum,
            liftid:record.liftid,
            devicegroupid:record.devicegroupid,
            paramFlag:{
              mqttFlag:0,
              iotFlag:0,
              videoAiFlag:0,
              linphoneFlag:0,
            }
          }
        })
      } else if (type == 'update') {
        this.updateVersion('', record);
      } else if (type == 'recover') {
        let time = moment(new Date()).format("YYYYMMDDHHmmss")
        let recoverid;
        this.devicenums=[]
        this.devicenames=[]
        let param = {
          username: this.username,
          recoverobj: record.devicegroupname,
          recoverdate: time,
          devicenum: '',
          devicegroupid: '0',
          orderresult: '',
          recovertype: '0',
        }
        addRecoverLog(param).then(res => {
          if (res && res.returncode == '0') {
            recoverid = res.item[0].recoverid
            let params = {
              devicegroupid: record.devicegroupid
            }
            getDevicegroupdevListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                let total = res.item.length
                for (var i = 0; i < res.item.length; i++) {
                  this.devicenums.push(res.item[i].devicenum);
                  this.devicenames.push(res.item[i].devicename);
                  this.recover(record,this.devicenums,this.devicenames,total,recoverid,i,time);
                }
              }
            })
          }
        })
      }
    },
    recover(record,devicenums,devicenames,total,recoverid,i,time) {
        let paramsBody = {
          Reboot: '1'
        }
        let params = {
          devicenum: devicenums[i],
          data: [
            {
              modeltype: '1',
              modelnum: '',
              params: {
                methord: 'PUT',
                url: '/mqttclient',
                body: paramsBody,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.sum = this.sum + 1
            let param = {
              username: this.username,
              recoverobj: devicenames[i],
              recoverdate: moment(new Date()).format("YYYYMMDDHHmmss"),
              devicenum: devicenums[i],
              devicegroupid: record.devicegroupid,
              orderresult: '成功',
              recovertype: '1',
              parentid: recoverid
            }
            addRecoverLog(param).then(res => {
              if (res && res.returncode == '0') {
                let param = {
                  recoverid: recoverid,
                  username: this.username,
                  recoverobj: record.devicegroupname,
                  recoverdate: time,
                  devicenum: '',
                  devicegroupid: '0',
                  orderresult: (this.sum / total * 100).toFixed(2) + "%",
                  recovertype: '0',
                }
                modifyRecoverLog(param)
              }
            })
            this.$message.success('操作成功');
          } else {
            this.$message.error(res.errormsg || '操作失败');
            let param = {
              username: this.username,
              recoverobj: devicenames[i],
              recoverdate: moment(new Date()).format("YYYYMMDDHHmmss"),
              devicenum: devicenums[i],
              devicegroupid: record.devicegroupid,
              orderresult: '失败',
              recovertype: '1',
              parentid: recoverid
            }
            addRecoverLog(param)
          }
        }).catch(err => {
          // this.hideLoading();
          this.$message.error('操作失败');
          let param = {
            username: this.username,
            recoverobj: devicenames[i],
            recoverdate: moment(new Date()).format("YYYYMMDDHHmmss"),
            devicenum: devicenums[i],
            devicegroupid: record.devicegroupid,
            orderresult: '失败',
            recovertype: '1',
            parentid: recoverid
          }
          addRecoverLog(param)
        })
    },
    //延时
    sleep(numberMillis) {
      var now = new Date();
      var exitTime = now.getTime() + numberMillis;
      while(true) {
        now = new Date();
        if (now.getTime() > exitTime)
          return;
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.devicegroupid);
      }).catch(()=>{
      });
    },
    delete(devicegroupid) {
      this.showLoading();
      if(devicegroupid) {
        let params = {
          devicegroupid
        };
        deleteDevicegroup(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
    managerDevice(value, record) {
      this.devicegroupid = record.devicegroupid;
      this.groupManagerVisible = true;
    },
    paramsConfig(value, record) {
      this.devicegroupid = record.devicegroupid;
      this.paramsModalVisible = true;
    },
    updateVersion(value, record) {
      this.devicegroupid = record.devicegroupid;
      this.updateVersionVisible = true;
    },
  }
}
</script>
<style lang="scss" scoped>
</style>